import React from 'react';
// import {Button, ButtonToolBar} from 'react-bootstrap';
// import {ConfirmDelete} from './ConfirmDelete';
import { Button } from "react-bootstrap"

const TableViewHeader = () => {
  return (
    <thead>
      <tr>
        <th>Date of Surgery</th>
        <th>Patient Last Name</th>
        <th>MRN</th>
        <th>Surgery</th>
        <th>Fellow/Resident(ID)</th>
        <th>Attending</th>
      </tr>
    </thead>
  )
}

const TableViewBody = props => {
  
  // const Names = props.fellowNames;
  // const surgeryTypes = props.surgeryTypes;
  
  const rows = props.fellowData.map((row, index) => {
    return (
      <tr key={index}>
        <td>{row.date_surgery}</td>
        <td>{row.patient_name_last}</td>
        <td>{row.mrn}</td>
        <td>{row.surgery_abbreviation} ({row.surgery_type})</td>
        <td>{row.fellow_name_last}, {row.fellow_name_first}</td>
        <td>{row.name_last_attending}</td>
      </tr>
    )
  })
  return <tbody>{rows}</tbody>
}

const TableView = props => {
    const { fellowData, fellowNames, surgeryTypes } = props

    return (
      <table>
        <TableViewHeader />
        <TableViewBody fellowData={fellowData} surgeryTypes={surgeryTypes} fellowNames={fellowNames} />
      </table>
    )
  }




export default TableView