import React from 'react';
// import {Button, ButtonToolBar} from 'react-bootstrap';
// import {ConfirmDelete} from './ConfirmDelete';
import { Button } from "react-bootstrap"

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>Date of Surgery</th>
        <th>Patient Last Name</th>
        <th>MRN</th>
        <th>Surgery</th>
        <th>Fellow/Resident(ID)</th>
        <th>Attending</th>
        <th>Remove</th>
        {/* <th>Update</th> */}
      </tr>
    </thead>
  )
}

const TableBody = props => {
  
  // const Names = props.fellowNames;
  // const surgeryTypes = props.surgeryTypes;
  
  const rows = props.fellowData.map((row, index) => {
    return (
      <tr key={index}>
        <td>{row.date_surgery}</td>
        <td>{row.patient_name_last}</td>
        <td>{row.mrn}</td>
        <td>{row.surgery_abbreviation} ({row.surgery_type})</td>
        <td>{row.fellow_name_last}, {row.fellow_name_first}</td>
        <td>{row.name_last_attending}</td>
        <td>
          <Button variant="danger" onClick={() => props.removeFellow(index)}>Delete</Button>
        </td>
      </tr>
    )
  })
  return <tbody>{rows}</tbody>
}

const Table = props => {
    const { fellowData, fellowNames, surgeryTypes, removeFellow } = props

    return (
      <table>
        <TableHeader />
        <TableBody fellowData={fellowData} surgeryTypes={surgeryTypes} fellowNames={fellowNames} removeFellow={removeFellow} />
      </table>
    )
  }


export default Table