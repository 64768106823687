import React from 'react'; //, { Component }
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
// import { render } from "react-dom"
import '../index.css'
import axios from "axios"
import { Nav } from 'react-bootstrap';

const APIprofile = "/api/profile/getProfile/";
const apiUpdateProfile = "/api/profile/updateProfile/" ;



class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      profile: [],
      editingApi: false,
      newApi: "",
      errorMessage: '',
      errorPresent: false 
    };
    this.handleApiEditClick = this.handleApiEditClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleApiSave = this.handleApiSave.bind(this);
  }

  handleApiEditClick(){
    this.setState(state => ({
      editingApi: !state.editingApi
    }));
  }

  handleApiSave(event){
    this.handleApiEditClick();
    const dataPacket = {newApi: ""};
    if (this.state.newApi === this.state.profile.userApi){
      console.log("the API is the same as before")
      this.setState({errorMessage: ''});
      this.setState({errorPresent: false});
    } else {
      console.log("Submitting the new API to the database")
      dataPacket.newApi = this.state.newApi;
      console.log(dataPacket);
      console.log(this.state);
      this.submitForm(dataPacket);
    } 
  }

  apiRowEdited(){
    return(
      <>
        <td>
          {this.state.profile.userApi} 
        </td>
        <td>
          <button onClick={this.handleApiEditClick}>Edit API</button>
        </td>
      </>
    );
  }

  apiRowEditing(){
    return(
      <>
        <td>
         <input
          type="text"
          required
          value={this.state.newApi}
          onChange={this.handleChange}
         /> 
        </td>
        <td>
         <button onClick={this.handleApiSave}>Save API</button>
        </td>
      </>
    );
  }

  handleChange(event){
    this.setState({newApi: event.target.value})
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token ) {
      if (this.props.token) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
          console.log("token to follow");
          console.log(this.props.token);
        axios.all([
          axios.post(APIprofile),
        ])
        .then(axios.spread((...responses) => {
          const ProfileAPIData = responses[0].data;
          console.log(ProfileAPIData.data);
          this.setState({profile: ProfileAPIData.data});
          this.setState({newApi: this.state.profile.userApi});
          console.log("state to follow");
          console.log(this.state);
        }, (error) => 
        {
          console.log(error);
        }))
      } else {
        console.log("no token.")
      }
    } else {
      // nothing
    }
  }

  componentDidMount() {

  }

  submitForm = (dataPacket) => {
    console.log("AXIOS call for updating API");
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
        console.log("token to follow");
        console.log(this.props.token);
    axios.post(apiUpdateProfile, dataPacket)
    // axios.post(apiUpdateProfile, this.state.newApi)
    .then((response) => {
        // Success 🎉
        console.log(response);
        // this.props.handleSubmit(this.state)
        console.log(this.state)
        // this.setState(this.initialState)
        window.location.reload();
        console.log("it worked!");
        this.setState({errorMessage: ''});
        this.setState({errorPresent: false});
    })
    .catch((error) => {
        // Error 😨
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */

            this.setState({error: error.response.data.errors});
            this.setState({message: error.response.data.message});
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.setState({errorMessage: error.response.data.detail});
            this.setState({errorPresent: true});
            this.setState({editingApi: true});
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
}


  render() {
    var { profile } = this.state;
    const apiEditing = this.state.editingApi;
    let apiRow;
    if (apiEditing){
      apiRow = this.apiRowEditing(profile.userApi);
    } else {
      apiRow = this.apiRowEdited(profile.userApi);
    }

    return (
      <div class="container">
          {this.props.isAuthenticated ?          
          <>
          {this.state.errorPresent && <p style={{ color: "#ff6600", textAlign: 'center'}}>{this.state.errorMessage}</p>}
            <table>
              <tr>
                  <td>
                      Last Name
                  </td>
                  <td>
                    {profile.name_last}
                  </td>
              </tr>
              <tr>
                  <td>
                      First Name
                  </td>
                  <td>
                    {profile.name_first}
                  </td>
              </tr>
              <tr>
                  <td>
                      Institution
                  </td>
                  <td>
                    {profile.company_name}
                  </td>
              </tr>
              <tr>
                  <td>
                      API
                  </td>
                    {apiRow}
              </tr>
            </table>
            <Nav.Link href="/changepassword">Change Password</Nav.Link>
          </>  
          : 
          <>
          <Card>
          <Card.Body>
            {/* <Card.Title className="text-center">Unauthorized</Card.Title> */}
            <Card.Text>
            You are not authenticated to view this page.
            </Card.Text>
            <div className="text-center">
              <a href="/login/">
                <Button variant="dark">Login</Button>
              </a>
            </div>
          </Card.Body>
          </Card>
          </>
          }


      
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }

  const mapStateToProps = state => {
    return {
      token: state.token,
      isAuthenticated: state.token !== null
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Admin);