// import React, { Component, useState } from 'react'
import React, { Component } from 'react'
import axios from 'axios'
// import { render } from "react-dom"
// import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Modal'
import { connect } from 'react-redux';

const APInewsurgery = "/api/surgeryschedulesfrontend/createSurgeryScheduleFromFrontend/" ;
// const APIsurgerytypes = "/api/surgerytypes/";
// const options = {
//     headers: {'Access-Control-Allow-Origin':'*'}
//   };

       

class Form extends Component {



    initialState = {
            surgeryTypes: [],
            fellowList: [],
            date_surgery: '', 
            patient_name_last: '', 
            patient_name_first: '', 
            mrn: '', 
            surgery_type: '', 
            record_id: '',
            attending_id: '',
            error: {
                date_surgery: false, 
                patient_name_last: false, 
                patient_name_first: false, 
                mrn: false, 
                surgery_type: false, 
                record_id: false,
                attending_id: false,
            }, 
    };
    
    // componentDidMount() {
    //     console.log("AXIOS call for surgery type");
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json",
    //         Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
    //     axios.get(APIsurgerytypes)
    //     .then(res =>{
    //         const SurgeryTypes = res.data.results;
    //         console.log(SurgeryTypes);
    //         this.setState({ surgeryTypes: this.props.surgeryTypes}) 
    //         this.setState({ surgeryTypes: SurgeryTypes})
    //     }, (error) => 
    //     {
    //       console.log(error);
    //     })
    // }

    //This is a legacy lifecycle coponent now
    componentWillReceiveProps(nextProps) {
        if (nextProps.surgeryTypes !== this.props.surgeryTypes) {
            this.setState({ surgeryTypes: nextProps.surgeryTypes}) 
            
        }
        console.log(nextProps.surgeryTypes);
    }
    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (nextProps.surgeryTypes !== this.props.surgeryTypes) {
    //         this.setState({ surgeryTypes: nextProps.surgeryTypes}) 
            
    //     }
    //     console.log(nextProps.surgeryTypes);
    // }

    state = this.initialState
  
    handleChange = event => {
        const { name, value } = event.target 
        this.setState({
            [name]: value,
        })
    }

    componentDidUpdate(nextProps) {
        if (nextProps.record_id !== this.props.record_id) {
          this.setState({ record_id: nextProps.record_id })
        }
        console.log(nextProps.surgeryTypes);
      }


    changeSelect = nextProps => {
        this.handleChange(nextProps)
        this.componentDidUpdate(nextProps)
    }  

    submitForm = () => {
        console.log("AXIOS call for submitting");
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
            console.log("token to follow");
            console.log(this.props.token);
        axios.post(APInewsurgery, this.state)
        .then((response) => {
            // Success 🎉
            console.log(response);
            this.props.handleSubmit(this.state)
            console.log(this.state)
            this.setState(this.initialState)
            console.log("it worked!")
        })
        .catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */

                this.setState({error: error.response.data.errors});
                this.setState({message: error.response.data.message});
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    render() {
        // this.setState({
        //     surgeryTypes: [],
        //     fellowList: [],
        //     date_surgery: '', 
        //     patient_name_last: '', 
        //     patient_name_first: '', 
        //     mrn: '', 
        //     surgery_type: '', 
        //     record_id: '',
        //     attending_id: '',
        //     error: {
        //         date_surgery: false, 
        //         patient_name_last: false, 
        //         patient_name_first: false, 
        //         mrn: false, 
        //         surgery_type: false, 
        //         record_id: false,
        //         attending_id: false,
        //         }
        //     });

        const {date_surgery, 
            patient_name_last, 
            patient_name_first, 
            mrn, } = this.state;
            
            // const {date_surgery, 
            //     patient_name_last, 
            //     patient_name_first, 
            //     mrn, 
            //     surgery_type, 
            //     record_id,
            //     attending_id,} = this.state;

        return(
            <form>
                <table>
                <thead>
                    <tr>
                        <td>
                            <label htmlFor="date_surgery">Date of Surgery</label>
                        </td>
                        <td>
                            <label htmlFor="patient_name_last">Patient Last Name</label>
                        </td>
                        <td>
                            <label htmlFor="patient_name_first">Patient First Name</label>
                        </td>
                        <td>
                            <label htmlFor="mrn">MRN</label>
                        </td>
                        <td>
                            <label htmlFor="surgery_type">Surgery Type</label>
                        </td>
                        <td>
                            <label htmlFor="record_id">Fellow/Resident</label>
                        </td>
                        <td>
                            <label htmlFor="attending_id">Attending</label>
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <input
                                type="date"
                                name="date_surgery"
                                data-date-format="DD MMMM YYYY"
                                id="date_surgery"
                                value={date_surgery}
                                style={{ border: this.state.error.date_surgery ? '1px solid red' : '' }}
                                onChange={this.handleChange} />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="patient_name_last"
                                id="patient_name_last"
                                value={patient_name_last}
                                style={{ border: this.state.error.patient_name_last ? '1px solid red' : '' }}
                                onChange={this.handleChange} />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="patient_name_first"
                                id="patient_name_first"
                                value={patient_name_first}
                                style={{ border: this.state.error.patient_name_first ? '1px solid red' : '' }}
                                onChange={this.handleChange} />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="mrn"
                                id="mrn"
                                value={mrn}
                                style={{ border: this.state.error.mrn ? '1px solid red' : '' }}
                                onChange={this.handleChange} />
                        </td>
                        {console.log("Form the FORM!")}
                        {console.log(this.props.surgeryTypes)}
                        <td>
                            <select id="surgery_type" name="surgery_type" value={this.state.surgery_type} style={{ border: this.state.error.surgery_type ? '1px solid red' : '' }} onChange={this.handleChange}>
                                <option value=''> --- </option>
                            {this.props.surgeryTypes.map((type) => (
                                    <option key={type.id} value={type.id}>{type.surgery}</option>
                            ))}
                            </select> 
                        </td>
                        <td>
                            <select id="record_id" name="record_id" value={this.state.record_id} style={{ border: this.state.error.record_id ? '1px solid red' : '' }} onChange={this.handleChange}>
                                    <option value=''>---</option>
                            {this.props.fellowList.filter(fellow => fellow.ed_status<=2 && fellow.active === true).map(filteredFellow => (
                                    <option key={filteredFellow.record_id} value={filteredFellow.record_id}> {filteredFellow.name_last}, {filteredFellow.name_first} </option>
                            ))};
                            </select>  
                        </td>
                        <td>
                            <select id="attending_id" name="attending_id" value={this.state.attending_id} style={{ border: this.state.error.attending_id ? '1px solid red' : '' }} onChange={this.handleChange}>
                                    <option value=''>---</option>
                            {this.props.fellowList.filter(fellow => fellow.ed_status==="3" && fellow.active === true).map(filteredFellow => (
                                    <option key={filteredFellow.record_id} value={filteredFellow.id}>{filteredFellow.name_last}, {filteredFellow.name_first}</option>
                            ))}
                            </select> 
                        </td>
                        <td>
                            <input type="button" value="Submit" onClick={this.submitForm} />
                        </td>
                    </tr>
                </tbody>
                </table>
                { this.state.message && <div style={{color: "red" , textAlign: "center"}}> {this.state.message} </div> } 
            </form>
        );
    }
}
const mapStateToProps = state => {
  return {
    token: state.token,
    isAuthenticated: state.token !== null
  }
}

export default connect(mapStateToProps)(Form);
