import React from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import Navbar from 'react-bootstrap/Navbar';
// import { Button, Nav, NavDropdown, Form, FormControl } from 'react-bootstrap';
import { Nav, NavDropdown} from 'react-bootstrap';
import { Layout } from 'antd';
// import { LogoutOutlined, UserOutlined, PieChartOutlined, LoginOutlined } from '@ant-design/icons';
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

// const { SubMenu } = Menu;

const {
  Content, Footer, Header
} = Layout;


class CustomLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {
    // let href=window.location.href.split('/');
    // href=href[3];
    // var defaultSelectedKeys = "";
    // if ( href !== ""){
    //   defaultSelectedKeys = href;
    // } else {
    //   defaultSelectedKeys = 100;
    // };
    return (

      <Layout style={{ minHeight: '100vh' }}>

            { this.props.isAuthenticated ?
             <nav className="navbar navbar-dark bg-dark fixed-top">
                <Navbar className="fixed-top" bg="dark" expand="lg">
                    <Navbar.Brand href="/">
                        {this.state.collapsed
                            ? 
                            <img src={"/django_static/img/nabu_short.png"} style={{ width: "100%", padding: "10px" }} alt="NC"/>
                            : 
                            <img
                                        src="/django_static/img/nabu_full.png"
                                        height="30"
                                        className="d-inline-block align-top"
                                        alt="NabuConnect logo"
                                    />}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto navbar-right">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/login/" onClick={this.props.logout}>Logout</Nav.Link>
                        <Nav.Link href="/about/">About</Nav.Link>
                        <NavDropdown title="Services" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/track">Track</NavDropdown.Item>
                            {/* <NavDropdown.Divider /> */}
                            {/* <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                        </NavDropdown>
                        <NavDropdown title="Tools" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                            <NavDropdown.Item href="/admin">Admin</NavDropdown.Item>
                            <NavDropdown.Item href="/participants">Manage/Add Participants</NavDropdown.Item>
                            {/* <NavDropdown.Divider /> */}
                            {/* <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                        </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                </nav>
                :
                <nav className="navbar navbar-dark bg-dark fixed-top">
                <Navbar className="fixed-top justify-content-right" bg="dark" variant="dark" expand="lg">
                    <Navbar.Brand href="/">
                        {this.state.collapsed
                            ? 
                            <img src={"/django_static/img/nabu_short.png"} style={{ width: "100%", padding: "10px" }} alt="NC"/>
                            : 
                            <img
                                        src="/django_static/img/nabu_full.png"
                                        height="30"
                                        className="d-inline-block align-top"
                                        alt="NabuConnect logo"
                                    />}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/login/">Login</Nav.Link>
                            <Nav.Link href="/about/">About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                </nav>
            }
    
        <Header></Header>
        <Layout>
        {/* <Layout className="site-layout" style={{ marginLeft: 200 }}> */}
            <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                <div className="site-layout-background" style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                {this.props.children}
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                NabuConnect <span role="img" aria-label="copyright">©️</span>2020 Created by RNWVB
            </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout));
