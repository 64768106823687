import React from 'react'; 
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import { Button } from "react-bootstrap"
import '../App.css'
import axios from "axios";
import Card from 'react-bootstrap/Card';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const APIprofile = "/api/profile/getProfile/";
const apiPasswordChange = '/api/auth/changePassword/';


class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
        errorNewPassword1: false,
        errorNewPassword2: false,
        errorOldPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token ) {
      if (this.props.token) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
          console.log("token to follow");
          console.log(this.props.token);
        axios.all([
          axios.post(APIprofile),
        ])
        .then(axios.spread((...responses) => {
          console.log(responses);
          const ProfileAPIData = responses[0].data;
          console.log(ProfileAPIData.data);
          this.setState({profile: ProfileAPIData.data})
          console.log("state to follow")
          console.log(this.state);
          this.setState({token: this.props.token});
        }, (error) => 
        {
          console.log(error);
        }))
      } else {
        console.log("no token.")
      }
    } else {
      // nothing
    }
  }

  componentDidMount() {

  }

  sendApi(api) {
    console.log("AXIOS call for submitting updates");
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
        console.log(this.props.token);
    axios.post(api)
    .then((response) => {
        // Success 🎉
        console.log(response);
        console.log("it worked!")
    })
    .catch((error) => {
        // Error 😨
        if (error.response) {
            /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */

            this.setState({error: error.response.data.errors});
            this.setState({message: error.response.data.message});
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            /*
                * The request was made but no response was received, `error.request`
                * is an instance of XMLHttpRequest in the browser and an instance
                * of http.ClientRequest in Node.js
                */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
  }

  handleChange(event){
    const value = event.target.value;
    // this.setState({
    //   ...this.state.participant,
    //   [event.target.name]: value
    // });
    this.setState({[event.target.name]: value});
    this.setState({formFilled: false});
  }


  displayMessage() {
    return(
      <p>{this.state.messageData}</p>
    )
    
  }

  clearForm(){
    this.setState({errorOldPassword: false});
    this.setState({errorNewPassword1: false});
    this.setState({errorNewPassword2: false});
    this.setState({errorPresent: false});
    this.setState({oldPassword: ""})
    this.setState({newPassword1: ""})
    this.setState({newPassword2: ""})
  }

  handleSubmit = (e) => {
    e.preventDefault()
      this.setState({errorOldPassword: false});
      this.setState({errorNewPassword1: false});
      this.setState({errorNewPassword2: false});
      this.setState({errorPresent: false});

      if (this.state.oldPassword === '') {
        this.setState({errorOldPassword: true});
        this.setState({errorPresent: true});
        this.setState({messagePresent: true});
        this.setState({message: "You must insert your old password"});
      } else if (this.state.newPassword1 === '') {
        this.setState({errorNewPassword1: true});
        this.setState({errorPresent: true});
        this.setState({messagePresent: true});
        this.setState({message: "You must chose a new password"});
      } else if (this.state.newPassword2 === '') {
        this.setState({errorNewPassword2: true});
        this.setState({errorPresent: true});
        this.setState({messagePresent: true});
        this.setState({message: "Please confirm your new password"});
      } else if (this.state.newPassword1 !== this.state.newPassword2) {
        this.setState({errorNewPassword1: true});
        this.setState({errorNewPassword2: true});
        this.setState({errorPresent: true});
        this.setState({messagePresent: true});
        this.setState({message: "Your passwords do not match"});
      } else if (this.state.oldPassword === this.state.newPassword1) {
        this.setState({errorNewPassword1: true});
        this.setState({errorPresent: true});
        this.setState({messagePresent: true});
        this.setState({message: "Your new password cannot be the same as your old password"});
      } else {
        this.handlePasswordChange();
      } 
  }

  // need to update to submit once it's been validated
  handlePasswordChange = () => {
    const changeAdress = apiPasswordChange + this.state.profile.id + "/"
    console.log(changeAdress)

    const passwordInfo = {
        "old_password": this.state.oldPassword,
        "password": this.state.newPassword1,
      }

    console.log("TOKEN: " + this.props.token)
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.state.token}`, 'Access-Control-Allow-Origin':'*'};
    axios.post(apiPasswordChange, passwordInfo)
    .then((response) => {
        // Success 🎉
        console.log(response);
        console.log("it worked!")
        // this.props.history.push('/');
        // this.props.history.go(-1);
        // this.props.history.push('participants');
        this.setState({messagePresent: true});
        this.setState({message: "Password successfully changed"});
        this.clearForm();
    })
    .catch((error) => {
        // Error 😨
        if (error.response) {
            this.setState({messagePresent: true});  
            this.setState({errorOldPassword: true});
            this.setState({errorPresent: true});
            this.setState({message: error.response.data.detail})
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({messagePresent: true});
            this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({messagePresent: true});
            this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})
        }
        console.log(error.config);
    });
  }
 


  render() {

    return (
        <div class="container-fluid justify-content-center">
          {this.props.isAuthenticated ?
          <>
            
            <div className="participant-form">
              {this.state.message && this.displayMessage()}
                <h2>Change Your Password</h2>
                {this.state.messagePresent ? <p style={{ color: "#ff6600", textAlign: 'center'}}> {this.state.message} </p> : ""}
                <form>
                    <label>Old Password</label>
                    <input 
                        className="noError"
                        type="password"
                        required
                        name="oldPassword"
                        value={this.state.oldPassword}
                        onChange={this.handleChange} 
                        style={{ border: this.state.errorOldPassword ? '1px solid red' : '' }}
                    />
                    <label>New Password</label>
                    <input 
                        className="noError"
                        type="password"
                        name="newPassword1"
                        value={this.state.newPassword1}
                        onChange={this.handleChange} 
                        style={{ border: this.state.errorNewPassword1 ? '1px solid red' : '' }}
                    />
                    <label>Confirm New Password</label>
                    <input 
                        className="noError"
                        type="password"
                        required
                        name="newPassword2"
                        value={this.state.newPassword2}
                        onChange={this.handleChange}
                        style={{ border: this.state.errorNewPassword2 ? '1px solid red' : '' }}
                    />
                    <button onClick={this.handleSubmit}>Change Password</button>
                </form>
            </div>
          </>  
          : 
          <>
          <Card>
          <Card.Body>
            {/* <Card.Title className="text-center">Unauthorized</Card.Title> */}
            <Card.Text>
            You are not authenticated to view this page.
            </Card.Text>
            {/* <div className="text-center">
              <a href="/login/">
                <Button variant="dark">Login</Button>
              </a>
            </div> */}
          </Card.Body>
          </Card>
          </>
          }
        </div>  
      
    );
  }
}


  
  const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }

  const mapStateToProps = state => {
    return {
      token: state.token,
      isAuthenticated: state.token !== null
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

  //Need to test passwords and find a way to pass the 
//   Your password can’t be too similar to your other personal information.
// Your password must contain at least 8 characters.
// Your password can’t be a commonly used password.
// Your password can’t be entirely numeric.