
import '../App.css';
import {Button, Card } from "react-bootstrap"
import React, { Component } from "react"
import '../index.css'
import axios from "axios"
import Table from "../components/Table"
import Form from "../components/Form"
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

const APIfellows = "/api/fellows/";
const apiToggleActive = "/api/fellows/toggleFellowActive/";
const APIprofile = "/api/profile/getProfile/";

class Participants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fellows: [],
      participants: [],
      participantSelection: "all",
      filteredParticipants: [],
      profile: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterParticipants = this.filterParticipants.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token ) {
      if (this.props.token) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
          console.log("token to follow");
          console.log(this.props.token);
        axios.all([
          axios.get(APIfellows),
          axios.post(APIprofile),
        ])
        .then(axios.spread((...responses) => {
            console.log("Response: " + JSON.stringify(responses))
          const FellowAPIData = responses[0].data;
          const ProfileAPIData = responses[1].data;
          console.log("API DATA: " + FellowAPIData)
          this.setState({participants: FellowAPIData})
          this.setState({filteredParticipants: FellowAPIData})
          this.setState({profile: ProfileAPIData.data})
        }, (error) => 
        {
          console.log(error);
        }))
      } else {
        console.log("no token.")
      }
    } else {
      // nothing
    }
  }
  
  componentDidMount() {

  }
  
  handleSubmit = fellow => {
    this.setState({ data: [...this.state.data, fellow]})
    window.location.reload(false);
  }
//   {"id":"dd2a9267-8513-41b4-9f86-5b5c61560fe8","name_last":"Cat","name_first":"Happy","email":"TEST_CAT@TEST.ORG","record_id":"4","ed_status":"1","registered_in_redcap":"1","last_surgery_number":null,"emptySurgeries":[],"specialty":null,"institution":null}
  

  ParticipantTable = () => {
       const header = (
            <thead>
            <tr>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Role</th>
                {/* <th>Institution</th> */}
                <th>Email</th>
                <th>(de)Activate</th>
            </tr>
            </thead>
            )
            
    // let domain = this.state.institutions
    // .filter((institution) => institution.id.toString() === this.state.institution)
    // .map(function(institution) {return institution.domain})


        // if (this.state.participantSelection === "all") {
        //   this.filteredParticipants = this.state.participants
        // } else if (this.state.participantSelection === "fellows") {
        //   this.filteredParticipants = this.state.participants.filter((participant) => participant.ed_status === "1")
        // } else if (this.state.participantSelection === "residents") {
        //   this.filteredParticipants = this.state.participants.filter((participant) => participant.ed_status === "2")
        // } else if (this.state.participantSelection === "learners") {
        //   this.filteredParticipants = this.state.participants.filter((participant) => participant.ed_status !== "3")
        // } else if (this.state.participantSelection === "attendings") {
        //   this.filteredParticipants = this.state.participants.filter((participant) => participant.ed_status === "3")
        // }  
        const edStatusLabels = [
          {'1': "Fellow"},
          {'2': "Resident"},
          {'3': 'Attending'}
        ]

        const rows = this.state.filteredParticipants.map((participant) => (            
          <tr key={participant.id}>
              <td>{participant.name_last}</td>
              <td>{participant.name_first}</td>
              <td>{participant.ed_status}</td>
              {/* <td>{participant.institution}</td> */}
              <td>{participant.email}</td>
              <td>
                  {participant.active ? 
                    <button className="nabu" onClick={() => this.toggleActive(participant.id, participant.active)}>Deactivate</button>
                    :
                    <button className="innactive" onClick={() => this.toggleActive(participant.id, participant.active)}>Activate</button>
                  }
                  
              </td>
          </tr>
      ))


        // const rows = this.state.participants.map((participant) => (            
        //     <tr key={participant.id}>
        //         <td>{participant.name_last}</td>
        //         <td>{participant.name_first}</td>
        //         <td>{participant.ed_status}</td>
        //         <td>{participant.institution}</td>
        //         <td>{participant.email}</td>
        //         <td>
        //             <button className="nabu">Delete</button>
        //         </td>
        //     </tr>
        // ))
    
    return (
        <>        
        <table style={{"fontSize": "0.9rem"}}>
          {header}
          {rows}
        </table> 
        <h4>Info:</h4>
        </>
        

    )
  }

  handleChange(event){
    const value = event.target.value;
    console.log("VALUE: " + value)
    console.log("EVENT NAME: " + event.target.name)
    this.setState({[event.target.name]: value}); 
    console.log("after set: " + this.state.participantSelection) //for some reason this isn't setting it right away
    this.filterParticipants(value)
  }

  filterParticipants(prop) {
    console.log("RECEIVED VALUE: " + prop)
    let filtered = []

    if (prop === "all") {
        console.log("ALL")
        this.setState({filteredParticipants: this.state.participants})
    } else if (prop === "fellows") {
        console.log("FELLOWS");
        let allParticipants = this.state.participants;
        allParticipants
        .filter(participant => participant.ed_status === '1')
        .map(filteredParticipant => filtered.push(filteredParticipant)) 
        this.setState({filteredParticipants: filtered});
        console.log(filtered);
    } else if (prop === "residents") {
        console.log("RESIDENTS")
        let allParticipants = this.state.participants;
        allParticipants
        .filter(participant => participant.ed_status === '2')
        .map(filteredParticipant => filtered.push(filteredParticipant)) 
        this.setState({filteredParticipants: filtered});
        console.log(filtered);
    } else if (prop === "learners") {
        console.log("LEARNERS")
        let allParticipants = this.state.participants;
        allParticipants
        .filter(participant => participant.ed_status === '1' || participant.ed_status === '2')
        .map(filteredParticipant => filtered.push(filteredParticipant)) 
        this.setState({filteredParticipants: filtered});
        console.log(filtered);
    } else if (prop === "attendings") {
        console.log("ATTENDINGS")
        let allParticipants = this.state.participants;
        allParticipants
        .filter(participant => participant.ed_status === '3')
        .map(filteredParticipant => filtered.push(filteredParticipant)) 
        this.setState({filteredParticipants: filtered});
        console.log(filtered);
    } 
  }

  toggleActive(id, activeState) {
    // let participantId = id
    let payload = {
      "id": id,
      'active': !activeState
    }
    console.log(payload)
    this.sendToggle(payload)
  }

  sendToggle = (payload) => {
    console.log("LETS SUBMIT!")
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
    axios.post(apiToggleActive, payload)
    .then((response) => {
        // Success 🎉
        console.log(response);
        console.log("it worked!")
        window.location.reload();
    })
    .catch((error) => {
        // Error 😨
        if (error.response) {
             /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
  }





  render() {
      return (
          <div className="container">
          {this.props.isAuthenticated && this.state.profile.isAdmin === true?
          <>
            <h2 style={{"text-align": "center"}}>Manage Participants</h2>
            
            <h4 style={{"text-align": "center"}}>List of Currently Enrolled Participants</h4>
            <label>Select participants to display</label>
            <select
                      name="participantSelection"
                      // value={this.state.participantSelection}
                      onChange={this.handleChange}
                    >
                      <option value="all">All</option>
                      <option value="fellows">Fellows</option>
                      <option value="residents">Residents</option>
                      <option value="learners">All learners (Residents/Fellows)</option>
                      <option value="attendings">Attendings</option>
            </select>

            {this.ParticipantTable()}

            <p>Active/Innactive: If participants are marked as "active" they will appear in the Track application.</p>
            <p>Missing a participant? <a href='/addparticipants'><button className="nabu">Add a new one</button></a></p>

          </>  
          : 
          <>
          <Card>
          <Card.Body>
            {/* <Card.Title className="text-center">Unauthorized</Card.Title> */}
            <Card.Text>
            You are not authenticated to view this page.
            </Card.Text>
          </Card.Body>
          </Card>
          </>
          }
          </div>
        
        
      )
  }
}

const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }

const mapStateToProps = state => {
    return {
      token: state.token,
      isAuthenticated: state.token !== null
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Participants);

//toDo: 
// [] add filter for attending and learner 
// [] add button to add 
//[] make way to "inactivate" fellow so they don't show up on the list (then add innactivate/activate button/toggle)
//[] make alphabetical