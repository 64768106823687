
import '../App.css';
import {Button, Card } from "react-bootstrap"
import React, { Component } from "react"
// import { render } from "react-dom"
import '../index.css'
import axios from "axios"
import Table from "../components/Table"
import Form from "../components/Form"
import { connect } from 'react-redux';
import TableView from '../components/TableView';
// import { Redirect, Route } from 'react-router-dom'

const APIsurgery = "/api/surgeryschedulesfrontend/getSurgerySchedule/";
const APIfellows = "/api/fellows/";
const APIsurgerytypes = "/api/surgerytypes/";
const APIdeletesuffix = "/deleteSurgeryScheduleFromFrontend/";
const APIprofile = "/api/profile/getProfile/";

// const options = {
//   headers: {'Access-Control-Allow-Origin':'*'}
// };

// For authorization specific to person logged in. 
// axios.defaults.headers = {
//   "Content-Type": "application/json",
//   Authorization: `Token ${this.props.token}`}

class Track extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fellows: [],
      surgeryTypes: [],
      addModalShow: false,
      profile: [],
    };
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token ) {
      if (this.props.token) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
          console.log("token to follow");
          console.log(this.props.token);
        axios.all([
          axios.post(APIsurgery),
          axios.get(APIfellows),
          axios.get(APIsurgerytypes),
          axios.post(APIprofile),
        ])
        .then(axios.spread((...responses) => {
          const SurgeryScheduleAPIData = responses[0].data;
          const FellowAPIData = responses[1];
          const SurgeryTypes = responses[2];
          const ProfileAPIData = responses[3].data;
          console.log(SurgeryScheduleAPIData.data);
          console.log(FellowAPIData.data);
          console.log("THE SURGERY TYPE DATA");
          console.log(SurgeryTypes.data);
          this.setState({data: SurgeryScheduleAPIData.data})
          this.setState({fellows: FellowAPIData.data})
          this.setState({surgeryTypes: SurgeryTypes.data.results})
          this.setState({profile: ProfileAPIData.data});
        }, (error) => 
        {
          console.log(error);
        }))
      } else {
        console.log("no token.")
      }
    } else {
      // nothing
    }
  }
  
  componentDidMount() {

  }
  removeFellow = index => {
    const { data } = this.state
    console.log(data);
    console.log("index---------");
    console.log(data[index]);
    console.log(data[index].id);
    console.log(APIsurgery + data[index].id);
    this.setState({
      data: data.filter((fellow, i) => { //the "callback" takes up to 3 arguments, the first (fellow) = the element, the second (i) is the index. 
        return i !== index
      }),
    })
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
    axios.post("/api/surgeryschedulesfrontend/deleteSurgeryScheduleFromFrontend/", data[index])
        .then((response) => {
            // Success 🎉
            console.log("/api/surgeryschedulesfrontend/" + data[index].id + APIdeletesuffix);
            console.log(response);
            console.log("it worked! Surgery deleted");
        })
        .catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
  }
  
  handleSubmit = fellow => {
    this.setState({ data: [...this.state.data, fellow]})
    window.location.reload(false);
  }

  render() {
    var { data } = this.state;
    var { fellows } = this.state;
    var { surgeryTypes } = this.state;

    console.log(surgeryTypes);
      return (
          <div className="container">
          {this.props.isAuthenticated ?
          <>
            {
              this.state.profile.userApi !== "" & this.state.profile.isAdmin ?
              <Form handleSubmit={this.handleSubmit} surgeryTypes={surgeryTypes} fellowList={this.state.fellows}/>
              :
              <p>Please fill in a valid API in your <a href='/profile'>profile</a> and confirm that you have administrator status to submit cases</p> 
            }
            {
              this.state.profile.userApi !== "" & this.state.profile.isAdmin ?
              <Table fellowData={data} fellowNames = {fellows} surgeryTypes={surgeryTypes} removeFellow={this.removeFellow}/> 
              :
              <TableView fellowData={data} fellowNames = {fellows} surgeryTypes={surgeryTypes} />
            }
          </>  
          : 
          <>
          <Card>
          <Card.Body>
            {/* <Card.Title className="text-center">Unauthorized</Card.Title> */}
            <Card.Text>
            You are not authenticated to view this page.
            </Card.Text>
            <div className="text-center">
              <a href="/login/">
                <Button variant="dark">Login</Button>
              </a>
            </div>
          </Card.Body>
          </Card>
          </>
          }
          </div>
        
        
      )
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    isAuthenticated: state.token !== null,
  }
}

export default connect(mapStateToProps)(Track);

 