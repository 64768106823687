
import React, { Component } from "react"
// import { render } from "react-dom"
import '../App.css'
// import axios from "axios"
import { Jumbotron, Button } from "react-bootstrap";
import * as actions from '../store/actions/auth';
import { connect } from 'react-redux';

class Home extends Component {


  render() {
      return (
        <div className="container">
          <Jumbotron>
          <div>
          <h1> Welcome to NabuConnect</h1>
            <p>Competency-based evaluations.</p>
            <p>
              <a href="/about/">
              <Button variant="dark" >Learn more</Button>
              </a>
            </p>
          </div>
            
          </Jumbotron>
        </div>
      )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);