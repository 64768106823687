import React from 'react'; //{ Component }
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { Button } from "react-bootstrap"
import '../App.css'
import axios from "axios"
import Card from 'react-bootstrap/Card';
// import AdminTable from "../components/AdminTable"

const APIfellows = "/api/fellows/createFellowFromRedcap/";
const APIattendings = "/api/supervisors/createSupervisorFromRedcap/";
const APIsurgeries = "/api/surgeryschedules/createSurgeryScheduleFromRedcap/";
const APIresponseRalp = "/api/ralpresponses/createSurveyResponseFromRC/";
const APIprofile = "/api/profile/getProfile/";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      profile: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token ) {
      if (this.props.token) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
          console.log("token to follow");
          console.log(this.props.token);
        axios.all([
          axios.post(APIprofile),
        ])
        .then(axios.spread((...responses) => {
          const ProfileAPIData = responses[0].data;
          console.log(ProfileAPIData.data);
          this.setState({profile: ProfileAPIData.data})
          console.log("state to follow")
          console.log(this.state);
        }, (error) => 
        {
          console.log(error);
        }))
      } else {
        console.log("no token.")
      }
    } else {
      // nothing
    }
  }

  componentDidMount() {

  }

    sendApi(api) {
        console.log("AXIOS call for submitting updates");
        axios.defaults.headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
            console.log(this.props.token);
        axios.post(api)
        .then((response) => {
            // Success 🎉
            console.log(response);
            console.log("it worked!")
        })
        .catch((error) => {
            // Error 😨
            if (error.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */

                this.setState({error: error.response.data.errors});
                this.setState({message: error.response.data.message});
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

  render() {
   
    return (
        <div class="container-fluid justify-content-center">
          {this.props.isAuthenticated && this.state.profile.isSuperuser === true ?
          <>
          <div class="col-6">
                <table>
                    <tr>
                        <td>
                            Update fellows from REDCap
                        </td>
                        <td>
                            <Button variant="dark" onClick={() => this.sendApi(APIfellows)}>FELLOWS</Button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Update attendings from REDCap
                        </td>
                        <td>
                            <Button variant="dark" onClick={() => this.sendApi(APIattendings)}>ATTENDINGS</Button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Update surgeries from REDCap
                        </td>
                        <td>
                            <Button variant="dark" onClick={() => this.sendApi(APIsurgeries)}>SURGERIES</Button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Update RALP Survey Responses from REDCap
                        </td>
                        <td>
                            <Button variant="dark" onClick={() => this.sendApi(APIresponseRalp)}>SURVEYS</Button>
                        </td>
                    </tr>
                </table>
      
            </div>
          </>  
          : 
          <>
          <Card>
          <Card.Body>
            {/* <Card.Title className="text-center">Unauthorized</Card.Title> */}
            <Card.Text>
            You are not authenticated to view this page.
            </Card.Text>
            {/* <div className="text-center">
              <a href="/login/">
                <Button variant="dark">Login</Button>
              </a>
            </div> */}
          </Card.Body>
          </Card>
          </>
          }
        </div>  
      
    );
  }
}


  
  const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }

  const mapStateToProps = state => {
    return {
      token: state.token,
      isAuthenticated: state.token !== null
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Admin);