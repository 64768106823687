
import React, { Component } from "react"
// import { render } from "react-dom"
import '../App.css'
// import axios from "axios"


class About extends Component {


  render() {
      return (
        <div className="container">
          <p>Currently under construction</p>
        </div>
      )
  }
}

export default About;