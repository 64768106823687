//check the imports and get form below what is needed. NPM install what is missing. 
//redux is needed to store authorization. 

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, compose, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import reducer from './store/reducers/auth';

const composeEnhances = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose

const store = createStore(reducer, composeEnhances(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <App />
  </Provider>
  )

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();

