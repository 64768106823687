import React from 'react';
import { Route } from 'react-router-dom';

import Login from './containers/Login';
import HomePage from './containers/Home';
import Track from './containers/Track';
import About from './containers/About';
import Admin from './containers/Admin';
import Profile from './containers/Profile';
import AddParticipants from './containers/AddParticipants';
import ResetPassword from './containers/ResetPassword';
import ChangePassword from './containers/ChangePassword';
import Participants from './containers/Participants';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';

const BaseRouter = () => (
  <div>
    <Route exact path='/login/' component={Login} />
    <Route exact path='/' component={HomePage} />
    <Route exact path='/track' component={Track} />
    <Route exact path='/about' component={About} />
    <Route exact path='/admin' component={Admin} />
    <Route exact path='/profile' component={Profile} />
    <Route exact path='/addparticipants' component={AddParticipants} />
    <Route exact path='/participants/' component={Participants} />
    <Route exact path='/resetpassword' component={ResetPassword} />
    <Route exact path='/changepassword/' component={ChangePassword} />
    <Route path="/accounts/reset/:uid/:token" component={ResetPasswordConfirm}/>
  </div>
);

export default BaseRouter;