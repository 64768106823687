import React from 'react';

import {
  Form, Input, Button, Spin, Checkbox, 
} from 'antd';
import {LoadingOutlined, UserOutlined, LockOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const antIcon = <LoadingOutlined />;


class NormalLoginForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loginErrorMessage: ''
    };
  };

//   handleSubmit = (err, values) => {
//     console.log(err);
//     console.log(values);
//     console.log("OMAR NEEDS ANT.D VERSION 4");
//     console.log(this.props.form);
//     // e.preventDefault();
//     this.props.err.validateFields((err, values) => {
//         console.log("OMAR NEEDS A HAIRCUT");
//       if (!err) {
//         this.props.onAuth(values.userName, values.password);
//       }
//     });
//   };

//   handleSubmit = (e, values) => {
//     // e.preventDefault();
//     console.log(values);
//     this.props.onAuth(values.userName, values.password);
//   };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      // console.log(this.props.error.response.status);
      // console.log(this.props.error.response.data);
      if (this.props.error) {
        if(this.props.error.response.status === 400){
          this.setState({
            loginErrorMessage: this.props.error.response.data.non_field_errors
          });
        } else if (this.props.error.response.status !== 400){
          this.setState({
            loginErrorMessage: "Unable to login. Credentials might be good though."
          });
        } else {
          // nothing
        };
      };
    };
    if(this.props.token !== prevProps.token) {
      if (this.props.token) {
          // this.props.history.push('/');
          this.props.history.go(-1);
      };
    };
  };

  render() {

    // let errorMessage = null;
    // if (this.props.error) {
    //   errorMessage = (
    //     <p>{this.props.error.message}</p>
    //   );
    // }

    // const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };

    // const prod = true;
    const onFinish = values => {
        console.log('Received values of form: ', values);
        this.props.onAuth(values.userName, values.password);
      };
    return (
      <div class="row d-flex justify-content-center">
        <div class="col-md-4">
        <h2>{this.state.loginErrorMessage}</h2>
        <>
          {this.props.loading
            ? <Spin indicator={antIcon} />
            :
          <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish} 
              >
              <Form.Item
                  name="userName"
                  {...formItemLayout}
                  rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                  name="password"
                  {...formItemLayout}
                  rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                  <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  />
              </Form.Item>
           
             <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
              </Form.Item>

              {/* <a className="login-form-forgot" href="mailto: admin@nabuconnect.com">
              Forgot password
              </a> */}
              </Form.Item>
              <div className='text-center'>
              <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit" className="login-form-button" onClick={this.handleSubmit}>
                  Log in
                  </Button>
                  {/* Or <a href="mailto: admin@nabuconnect.com">Inquire about registering now!</a> */}
                  <Nav.Link href="/resetpassword">Forgot your Password?</Nav.Link>
                  <Nav.Link href="/">Home</Nav.Link>
              </Form.Item>
              </div>
            
          </Form>
            }
          </>
        </div>
      </div>
    );
  }
}

// const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);