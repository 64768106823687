import React from 'react'; 
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import { Button } from "react-bootstrap"
import '../App.css'
import axios from "axios";
import Card from 'react-bootstrap/Card';

const APIprofile = "/api/profile/getProfile/";
const apiInstitution = "/api/institutions/";
const apiSpecialty = "/api/specialties/";
const apiSubmitParticipant = "/api/fellows/createFellowFromFrontend/"

class AddParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      profile: [],
      institutions: [],
      specialties: [],
      lastName: "",
      firstName: "",
      email: "",
      specialty: "",
      institution: "",
      role: "",
      dataErrors: false,
      formFilled: false,
      error: {
        lastName: false,
        firstName: false,
        email: false,
        specialty: false,
        institution: false,
        role: false
      },
      message: false,
      errorMessage: '',
      errorPresent: false 
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.dataConfirm = this.dataConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token ) {
      if (this.props.token) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
          console.log("token to follow");
          console.log(this.props.token);
        axios.all([
          axios.post(APIprofile),
          axios.get(apiInstitution),
          axios.get(apiSpecialty),
        ])
        .then(axios.spread((...responses) => {
          console.log(responses);
          const ProfileAPIData = responses[0].data;
          const institutionData = responses[1].data.results;
          const specialtyData = responses[2].data.results;
          console.log(ProfileAPIData.data);
          console.log("Institution data:" + institutionData);
          this.setState({profile: ProfileAPIData.data})
          this.setState({institutions: institutionData})
          this.setState({specialties: specialtyData})
          console.log("state to follow")
          console.log(this.state);
          this.setState({token: this.props.token});
        }, (error) => 
        {
          console.log(error);
        }))
      } else {
        console.log("no token.")
      }
    } else {
      // nothing
    }
  }

  componentDidMount() {

  }

  sendApi(api) {
    console.log("AXIOS call for submitting updates");
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`, 'Access-Control-Allow-Origin':'*'};
        console.log(this.props.token);
    axios.post(api)
    .then((response) => {
        // Success 🎉
        console.log(response);
        console.log("it worked!")
    })
    .catch((error) => {
        // Error 😨
        if (error.response) {
            /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */

            this.setState({error: error.response.data.errors});
            this.setState({message: error.response.data.message});
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            /*
                * The request was made but no response was received, `error.request`
                * is an instance of XMLHttpRequest in the browser and an instance
                * of http.ClientRequest in Node.js
                */
            console.log(error.request);
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
  }

  handleChange(event){
    const value = event.target.value;
    // this.setState({
    //   ...this.state.participant,
    //   [event.target.name]: value
    // });
    this.setState({[event.target.name]: value});
    this.setState({formFilled: false});
  }

  selectSpecialtyOptions(){
    return(
      <>
      <option value="">---</option>
      {this.state.specialties.map((specialty) => (
        <option key={specialty.id} value={specialty.id}>{specialty.specialty}</option>
      ))}
      </>
    )
  }

  selectInstitutionOptions(){
    return(
      <>
      <option value="">---</option>
      {this.state.institutions.map((institution) => (
        <option key={institution.id} value={institution.id}>{institution.name}</option>
      ))}
      </>
    )
  }

  displayMessage() {
    return(
      <p>{this.state.messageData}</p>
    )
    
  }

  clearForm(){
    this.setState({
      lastName: '',
      firstName: '',
      email: '',
      specialty: '',
      institution: '',
      role: '',
      formFilled: false
    });
  }

  handleSubmit = (e) => {
    e.preventDefault()
    console.log("LETS SUBMIT!")
    const participantPacket = {
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      email: this.state.email,
      specialty: this.state.specialty,
      institution: this.state.institution,
      role: this.state.role
    }
    console.log("AXIOS call for submitting New Participant" + participantPacket);
    console.log("TOKEN: " + this.props.token)
    axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.state.token}`, 'Access-Control-Allow-Origin':'*'};
    axios.post(apiSubmitParticipant, participantPacket)
    .then((response) => {
        // Success 🎉
        console.log(response);
        console.log("it worked!")
        // this.props.history.push('/');
        // this.props.history.go(-1);
        // this.props.history.push('participants');
        this.setState({message: true});  
        this.setState({messageData: `${this.state.firstName} ${this.state.lastName} was successfully added`})
        this.clearForm();
        this.setState({errorMessage: ''});
        this.setState({errorPresent: false});
    })
    .catch((error) => {
        // Error 😨
        if (error.response) {
            this.setState({message: true});  
            this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.setState({errorMessage: error.response.data.detail});
            this.setState({errorPresent: true});
            if(error.response.status === 406) {
              const errorList = [];
              errorList['email'] = true;
              this.setState({error: errorList})
            }
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({message: true});  
            this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})

        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({message: true});  
            this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})

        }
        console.log(error.config);
    });
  }

  handleAddClick = (e) => {
    e.preventDefault()
    // const newParticipantInfo = [this.state.lastName, this.state.firstName];
    const newParticipantInfo = [
      {
        var: "lastName",
        value: this.state.lastName
      },
      {
        var: "firstName",
        value: this.state.firstName
      },
      {
        var: "email",
        value: this.state.email
      },
      {
        var: "specialty",
        value: this.state.specialty
      },
      {
        var: "institution",
        value: this.state.institution
      },
      {
        var: "role",
        value: this.state.role
      },
    ];
    const incomplete = [];
    const errorList = {
      lastName: false,
      firstName: false,
      email: false,
      emailInvalid: false,
      specialty: false,
      institution: false,
      role: false
    };

    newParticipantInfo.map((info) => {
      if (info.value === "") {
        incomplete.push(info.var);
        errorList[info.var] = true;
      }
    });

    let domain = this.state.institutions
    .filter((institution) => institution.id.toString() === this.state.institution)
    .map(function(institution) {return institution.domain})
    let extension = this.state.institutions
    .filter((institution) => institution.id.toString() === this.state.institution)
    .map(function(institution) {return institution.extension})

    // console.log("INSTITUTE SELECTED: " + JSON.stringify(institutionData))
    console.log(`The email will include DOMAIN: ${domain} and EXTENSION: ${extension}`)

    
    
    let emailRegEx = new RegExp(`[a-z0-9]+@${domain}\.${extension}`, "g")
    console.log("EMAIL REGEX: " + emailRegEx.test(this.state.email))
    let emailValid = emailRegEx.test(this.state.email)
    console.log(emailValid)
    if (emailRegEx.test(this.state.email)) {
      console.log("IF");
      errorList['emailInvalid'] = false;
    } else {
      console.log("else")
      errorList['emailInvalid'] = true;
      errorList['email'] = true;
      incomplete.push("emailInvalid");
    }
  
    this.setState({error: errorList});
    if (incomplete.length !== 0) {
      this.setState({dataErrors: true})
    } else {
      this.setState({dataErrors: false})
      this.setState({formFilled: true})
    };
    
    return(
      console.log("testing")
    )
  }

  dataErrors(){
    return(
      <p style={{color: "#ff6600"}}>
        Please fill in all missing fields and assure a correct email address for the selected institution.
      </p>
     )
  }
  
  dataConfirm(){
    return(
      <div class="participant-form">
        <br></br>
        <h2>Confirm that Information is Correct or Edit Above</h2>
          <table>
          <tbody>
            <tr>
              <td>Last Name:</td>
              <td>{this.state.lastName}</td>
            </tr>
            <tr>
              <td>First Name:</td>
              <td>{this.state.firstName}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{this.state.email}</td>
            </tr>
            <tr>
              <td>Specialty:</td>
              {
                this.state.specialties
                .filter((specialty) => specialty.id.toString() === this.state.specialty)
                .map(specialty => <td>{specialty.specialty}</td>)
              }
            </tr>
            <tr>
              <td>Institution:</td>
              {
                this.state.institutions
                .filter((institution) => institution.id.toString() === this.state.institution)
                .map(institution => <td>{institution.name}</td>)
              }
            </tr>
            <tr>
              <td>Role:</td>
              <td>{this.state.role}</td>
            </tr>
          </tbody>
        </table>
        <br/>
          <button onClick={this.handleSubmit}>Confirm and Submit</button>
      </div>
    )
  }


  render() {

    return (
        <div class="container-fluid justify-content-center">
          {this.props.isAuthenticated && this.state.profile.isAdmin === true ?
          <>
            <div className="participant-form">
              {this.state.message && this.displayMessage()}
              {this.state.errorPresent && <p style={{ color: "#ff6600", textAlign: 'center'}}>{this.state.errorMessage}</p>}
                <h2>Add a New Participant</h2>
                {this.state.dataErrors ? this.dataErrors() : ""}
                <form>
                    <label>Last Name</label>
                    <input 
                        className="noError"
                        type="text"
                        required
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange} 
                        style={{ border: this.state.error.lastName ? '1px solid red' : '' }}
                    />
                    <label>First Name</label>
                    <input 
                        className="noError"
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange} 
                        style={{ border: this.state.error.firstName ? '1px solid red' : '' }}
                    />
                    <label>E-mail Address (Institutional)</label>
                    <input 
                        className="noError"
                        type="email"
                        required
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        style={{ border: this.state.error.email ? '1px solid red' : '' }}
                    />
                    <label>Specialty</label>
                    <select
                      className="noError"
                      name="specialty"
                      value={this.state.specialty}
                      onChange={this.handleChange}
                      style={{ border: this.state.error.specialty ? '1px solid red' : '' }}
                    >
                      {this.selectSpecialtyOptions()}
                    </select>
                    <label>Institution</label>
                    <select
                      className="noError"
                      name="institution"
                      value={this.state.institution}
                      onChange={this.handleChange}
                      style={{ border: this.state.error.institution ? '1px solid red' : '' }}
                    >
                      {this.selectInstitutionOptions()}
                    </select>
                    <label>Role</label>
                    <select
                      className="noError"
                      name="role"
                      value={this.state.role}
                      onChange={this.handleChange}
                      style={{ border: this.state.error.role ? '1px solid red' : '' }}
                    >
                      <option value="">---</option>
                      <option value="fellow">Fellow</option>
                      <option value="resident">Resident</option>
                      <option value="attending">Attending</option>
                    </select>
                    <br/>
                    <button onClick={this.handleAddClick}>Add New Participant</button>
                </form>
            </div>
            {this.state.formFilled && this.dataConfirm()}
          </>  
          : 
          <>
          <Card>
          <Card.Body>
            {/* <Card.Title className="text-center">Unauthorized</Card.Title> */}
            <Card.Text>
            You are not authenticated to view this page.
            </Card.Text>
            {/* <div className="text-center">
              <a href="/login/">
                <Button variant="dark">Login</Button>
              </a>
            </div> */}
          </Card.Body>
          </Card>
          </>
          }
        </div>  
      
    );
  }
}


  
  const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
  }

  const mapStateToProps = state => {
    return {
      token: state.token,
      isAuthenticated: state.token !== null
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AddParticipants);