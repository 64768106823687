import React from 'react';

import {
  Form, Input, Button, Spin, Checkbox, 
} from 'antd';
import {LoadingOutlined, UserOutlined, LockOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";

const antIcon = <LoadingOutlined />;
const apiPasswordReset = "/rest-auth/password/reset/"

class ResetPassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loginErrorMessage: ''
    };
  };

//   handleSubmit = (err, values) => {
//     console.log(err);
//     console.log(values);
//     console.log("OMAR NEEDS ANT.D VERSION 4");
//     console.log(this.props.form);
//     // e.preventDefault();
//     this.props.err.validateFields((err, values) => {
//         console.log("OMAR NEEDS A HAIRCUT");
//       if (!err) {
//         this.props.onAuth(values.userName, values.password);
//       }
//     });
//   };

//   handleSubmit = (e, values) => {
//     // e.preventDefault();
//     console.log(values);
//     this.props.onAuth(values.userName, values.password);
//   };
// handleSubmit = () => {
handleSubmit = values => {
  // console.log("handling submit: ", JSON.stringify(`"email": ${values.email}`))
  // const email = "wilcox.r@gmail.com"
  //    const email = {
  //     (values.email)
  //   }
    const email = values.email;
    console.log("THE EMAIL", JSON.stringify({email}))
  axios.defaults.headers = {
      "Content-Type": "application/json",
      // Authorization: `Token ${this.state.token}`, 'Access-Control-Allow-Origin':'*'
    };
  axios.post(apiPasswordReset, {email})
        // axios.post(apiPasswordReset, JSON.stringify({email}))
  .then((response) => {
      // Success 🎉
      console.log(response);
      console.log("it worked!")
      // this.props.history.push('/');
      // this.props.history.go(-1);
      // this.props.history.push('participants');
      this.setState({message: true});  
      this.setState({messageData: `${this.state.firstName} ${this.state.lastName} was successfully added`})
      this.clearForm();
  })
  .catch((error) => {
      // Error 😨
      if (error.response) {
          this.setState({message: true});  
          this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({message: true});  
          this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({message: true});  
          this.setState({messageData: "Request not successfull. Please check the validity of the submitted data and try again. If continued error, please contact your administrator."})

      }
      console.log(error.config);
  });
}

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      // console.log(this.props.error.response.status);
      // console.log(this.props.error.response.data);
      if (this.props.error) {
        if(this.props.error.response.status === 400){
          this.setState({
            loginErrorMessage: this.props.error.response.data.non_field_errors
          });
        } else if (this.props.error.response.status !== 400){
          this.setState({
            loginErrorMessage: "Unable to login. Credentials might be good though."
          });
        } else {
          // nothing
        };
      };
    };
    if(this.props.token !== prevProps.token) {
      if (this.props.token) {
          // this.props.history.push('/');
          this.props.history.go(-1);
      };
    };
  };

  render() {

    // let errorMessage = null;
    // if (this.props.error) {
    //   errorMessage = (
    //     <p>{this.props.error.message}</p>
    //   );
    // }

    // const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };

    // const prod = true;
    const onFinish = values => {
        console.log('Received values of form: ', values.email);
        this.handleSubmit(values)
        // this.props.onAuth(values.userName, values.password);
      };
    return (
      <div class="row d-flex justify-content-center">
        <div class="col-md-4">
        <h2>{this.state.loginErrorMessage}</h2>
        <>
          {this.props.loading
            ? <Spin indicator={antIcon} />
            :
          <Form
              name="reset-password"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish} 
              >
              <Form.Item
                  name="email"
                  {...formItemLayout}
                  rules={[{ required: true, message: 'Please input your Email Address!' }]}
              >
                  <Input value={this.state.email} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email Address" />
              </Form.Item>

              <div className='text-center'>
              <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit" className="login-form-button" onClick={this.handleSubmit}>
                  Reset Password
                  </Button>
                  <Nav.Link href="/">Home</Nav.Link>
              </Form.Item>
              </div>
            
          </Form>
            }
          </>
        </div>
      </div>
    );
  }
}

// const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);